@import '../../../styles/variables';

.about {
  position: relative;
  padding-top: 50px;
  &__italic {
    font-size: 3.2rem;
    font-style: italic;
  }
  &__background {
    @include background($color-grey);
  }
  &__wrapper {
    display: flex;
    font-size: 2.4rem;
    color: $color-white;
  }
  &__first {
    flex: 40%;
    font-family: $font-ledger;
    font-weight: 400;
    margin-right: 50px;
    &--bot {
      height: (100% - 12);
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      color: $color-beige;
      justify-content: space-between;
      font-size: 4.8rem;
      text-transform: uppercase;
      p {
        padding-bottom: 2rem;
      }
    }
  }
  &__second {
    flex: 60%;
    li {
      list-style: disc;
      margin-bottom: 30px;
    }
  }
}
.mg {
  margin-bottom: 30px;
}
@media only screen and (max-width: 1024px) {
  .about {
    &__wrapper {
      flex-direction: column;
    }
    &__italic {
      display: none;
    }
  }
}
