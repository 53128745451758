@import '../../../styles/variables';
.arrow {
  position: relative;
  &-left {
    position: absolute;
    top: 40%;
    left: -5%;
  }
  &-right {
    position: absolute;
    top: 40%;
    right: -5%;
    transform: rotate(180deg);
  }
}
.reviews {
  position: relative;
  padding-top: 50px;
  &__background {
    @include background($color-light-grey);
  }
  &__heading {
    display: flex;
    text-transform: uppercase;
    font-family: $font-ledger;
    font-size: 4.8rem;
    color: $color-dark-beige;
    &--logo {
      max-width: 225px;
      min-width: 100px;
      position: relative;
      top: -33px;
    }
  }
  &__content {
    &--top,
    &--bot {
      position: relative;
      gap: 20px;
      margin-bottom: 20px;
      display: flex;
      img {
        min-width: 0;
        background: $color-beige;
        flex: 1 1 30%;
      }
    }
  }
}
@media only screen and (max-width: 640px) {
  .arrow-left,
  .arrow-right {
    display: none;
  }
  .reviews {
    &__heading {
      margin-bottom: 50px;
      &--logo {
        display: none;
      }
    }
    &__content {
      &--top,
      &--bot {
        flex: 0 0 100%;
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          flex: 0 0 100%;
        }
      }
    }
  }
}
