.reset {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 30%;
  margin: auto;
  align-items: center;

  &__input {
    font-size: 3rem;
    width: 100%;
    padding: 5px 5px;
  }
  &__button {
    font-size: 3rem;
    width: 100%;
  }
}
