@import '../../styles/variables';
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal {
  position: fixed;
  top: 15vh;
  left: 25%;
  width: 50%;
  max-height: 70vh;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  overflow: auto;
  animation: slide-down 300ms ease-out forwards;
  border: 1rem solid white;
  &__top {
    display: flex;
    position: relative;
    justify-content: space-between;
    &--cross {
      position: absolute;
      width: 20px;
      right: 10px;
      top: 30%;
      bottom: 30%;
      cursor: pointer;
    }
  }
  h1 {
    font-family: $font-ledger;
    padding: 1rem 1rem;
  }
}
.line-1,
.line-2 {
  position: absolute;

  width: 20px;
  border: 1px solid $color-black;
}
.line-1 {
  transform: translateY(10px) rotate(45deg);
}
.line-2 {
  transform: translateY(10px) rotate(-45deg);
}
@media only screen and (max-width: 640px) {
  .modal {
    width: 100%;
    left: 0;
  }
}
