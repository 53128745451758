@import '../../styles/variables';
.card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 1rem 1rem;
  padding: 1rem 1rem;
  background: $color-light-grey;
  border-radius: 10px;
  font-family: $font-ledger;
  &__name {
    max-width: 60%;
    font-weight: 400;
    font-size: 1.5rem;
  }
  &__top,
  &__bot {
    font-size: 2rem;
    display: flex;
  }
  &__top {
    justify-content: space-between;
  }
  &__button {
    border: 2px solid $color-grey;
    padding: 0 2rem;
    border-radius: 30px;
    background: inherit;
    cursor: pointer;
    &:hover {
      background-color: $color-white;
    }
  }
  &__bot {
    justify-content: right;
    align-items: center;

    gap: 2rem;
  }
}
