.error {
  text-align: center;
  font-size: 3rem;
  padding: 2rem 2rem;
}
.total {
  font-size: 3rem;
  display: flex;
  justify-content: end;
  margin: 1rem 1rem;
}
