@import '../../styles/variables';
.profile {
  margin: 10rem auto;
  height: auto;
  width: 80%;
  max-width: 400px;
  font-family: $font-ledger;
  &__label {
    margin-bottom: 20px;
    label {
      display: flex;
      flex-direction: column;
    }
    input {
      padding: 5px 5px;
      border-radius: 10px;
      border: 1px solid $color-black;
    }
  }
  &__btn {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    background: $color-white;
    border: 1px solid $color-black;
    border-radius: 10px;
    margin-bottom: 2rem;
  }
}
.red {
  color: red;
}
// breakpoints
// 1280
// 1024
// 768
// 640
