@import './styles/variables';

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}
// breakpoints
// 1280
// 1024
// 768
// 640

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
@media only screen and (max-width: 1280px) {
  html {
    font-size: 55%;
  }
}
@media only screen and (max-width: 1024px) {
  html {
    font-size: 47.5%;
  }
}
@media only screen and (max-width: 768px) {
  html {
    font-size: 40%;
  }
}
@media only screen and (max-width: 640px) {
  html {
    font-size: 32.5%;
  }
}
body {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  font-size: 1.6rem;
  line-height: 1.5;
  padding-top: 100px;
  -webkit-font-smoothing: antialiased;
}
.container {
  margin: 0 auto;
  max-width: 86%;
  padding: 5rem 0;
  z-index: -1;
}
.lds-dual-ring {
  /* change color here */
  color: #1c4c5b;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.lds-dual-ring,
.lds-dual-ring:after {
  box-sizing: border-box;
}
.lds-dual-ring {
  display: inline-block;
  width: 120px;
  height: 120px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 120px;
  height: 120px;
  margin: 8px;
  border-radius: 50%;
  border: 10.4px solid currentColor;
  border-color: currentColor transparent currentColor transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.jc-fe {
  display: flex;
  justify-content: flex-end;
}
.jc-c {
  display: flex;
  justify-content: center;
}
.jc-sb {
  justify-content: space-between;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
li {
  list-style: none;
}
a {
  display: block;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.error {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  font-size: 3rem;
  padding: 10rem 10rem;
  border: 1px solid black;
  border-radius: 10px;
}
