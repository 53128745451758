@import '../../styles/variables';

.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  grid-gap: 2rem;

  &__product {
    img {
      background: $color-beige;
      aspect-ratio: 5/4;
      object-fit: cover;
      margin-bottom: 1rem;
    }
  }
}
.product {
  &__wrapper {
    padding: 2rem 2rem;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
    p {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 1rem;
      text-align: start;
      color: $color-grey;
    }
    h2 {
      font-size: 2rem;
      font-weight: 100;
      margin-bottom: 1rem;
      min-height: 6rem;
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-around;
  }
  &--btn {
    padding: 0.5rem 2rem;
    background: $color-beige;
    border: none;
    font-size: 2rem;
    border-radius: 30px;
    cursor: pointer;
  }
  &--btn:hover {
    background-color: $color-dark-beige;
    color: $color-beige;
  }
}
@media only screen and (max-width: 768px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
  }
  .product {
    &__wrapper {
    }
  }
}
