@import '../../../styles/variables';
.questions {
  position: relative;
  &__background {
    @include background($color-light-grey);
  }
  &__content {
    display: flex;
    justify-content: space-between;
    color: $color-dark-beige;
    font-size: 2.4rem;
    &--ul {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
    &--left {
      font-family: $font-ledger;
      font-size: 2em;
      flex: 33%;
    }
    &--dot {
      margin-top: 1.5rem;
      display: flex;
      width: 0.9rem;
      height: 0.9rem;
      border: 1px solid;
      border-radius: 90%;
      background-color: rgb(61, 46, 16);
    }
    .dot {
      background-color: transparent;
      border-color: rgba(198, 185, 168);
    }
    &--right {
      font-family: $font-zetta;
      font-size: 1em;
      flex: 66%;
      hr {
        margin-top: 0.5em;
        margin-bottom: 0.67em;
      }
      li {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
.animated__dropdown {
  animation: growDown 200ms ease-in-out forwards;
  transform-origin: top center;
}
@keyframes growDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@media only screen and (max-width: 768px) {
  .questions {
    &__content {
      display: block;
      &--left {
        margin-bottom: 50px;
      }
    }
  }
}
// @media only screen and (max-width: 640px) {
//   .questions {
//     &__content {
//       &--right {
//       }
//     }
//   }
// }
